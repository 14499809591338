@import '../../../variables';
@import '../../../mixins';

.passwordForm {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.row {
  display: flex;
  justify-content: flex-start;
  padding: 0 12px;
}

.label {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: left;
  padding: 0;
  margin: 10px 0;
}

.inputWrapper {
  position: relative;
  max-width: 515px;
  padding: 0;

  svg {
    position: absolute;
    bottom: 20px;
    right: 20px;
  }
}

.input {
  input {
    height: 55px;
    padding-right: 46px;
  }
}

.submit {
  height: 55px;
  max-width: 100%;

  @include min-w(map-get($breakpoints, 'md')) {
    max-width: 320px;
  }
}
