@import '../../variables';
@import '../../mixins';

.container {
  border: 1px solid $gray-disabled;
  border-radius: 10px;

  &.picked {
    border-color: $success-color;
  }

  @include shadowed-box;
}

.headerContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;

  .itemImage {
    background-color: $white;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    width: 84px;
    height: 84px;
    margin: 0 10px 0 20px;

    &:hover {
      cursor: zoom-in;
    }

    @include max-w(map-get($breakpoints, 'xs')) {
      width: 70px;
      height: 70px;
      margin: 0 10px 0 10px;
    }
  }

  .name {
    font-size: 18px;
    font-weight: bold;
    color: $ebony;
    align-self: center;
    margin-bottom: 0;
    margin-right: auto;

    .customizableItemIcon {
      position: relative;
      top: 5px;
    }

    @include max-w(map-get($breakpoints, 'xs')) {
      font-size: 16px;
    }
  }

  .quantity {
    font-weight: bold;
    margin-left: 0.3em;
  }

  .arrowButton {
    margin: 0 20px;

    @include max-w(map-get($breakpoints, 'xs')) {
      margin: 0;
    }
  }

  .checkmark {
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: flex-start;
    height: 20px;
    width: 100%;
    min-width: 20px;
    max-width: 20px;
    border-radius: 50%;
    border: 1px solid #ced4da;
    background-color: $gray-blue;
    z-index: 2;
    left: calc(50% - 30px);
    top: -30px;

    @include max-w(map-get($breakpoints, 'xs')) {
      height: 15px;
      width: 100%;
      min-width: 15px;
      max-width: 15px;
    }
  }

  .checkmark::after {
    position: absolute;
    content: '';
    height: 5px;
    width: 8px;
    margin-bottom: 1.5px;
    border-bottom: 2px solid white;
    border-left: 2px solid white;
    transform: rotate(-45deg);
  }

  &.picked {
    border-color: $success-color;

    .checkmark {
      background-color: $success-color;
      border-color: $success-color;
    }
  }
}

.optionsContainer {
  margin-right: 0;
  margin-left: auto;
  text-align: right;

  .optionsCount {
    font-size: 21px;
    font-weight: bold;
  }

  .detailsContainer {
    font-size: 16px;

    .selectedText {
      color: $gray-blue;
    }

    .selectedItems {
      margin-left: 5px;
      text-transform: capitalize;
    }
  }

  @include max-w(map-get($breakpoints, 'xs')) {
    margin-left: 20px;
    text-align: left;

    .optionsCount {
      font-size: 16px;
    }

    .detailsContainer {
      font-size: 12px;
    }
  }
}

.itemsContainer {
  display: grid;
  grid-template-columns: 1fr;

  margin: 0 20px 0 6.3vw;

  @include max-w(map-get($breakpoints, 'xl')) {
    grid-template-columns: 1fr;
  }

  @include max-w(map-get($breakpoints, 'xs')) {
    margin: 0;
  }
}

.simpleItem {
  &:hover {
    box-shadow: none;
    cursor: default;
  }
}
