@import 'src/variables';
@import 'src/mixins';

:root {
  --transform-opened: translate3d(-15%, -41%, 15px) scale(1) rotateX(-1deg) rotateY(-6deg) rotateZ(12deg);
  --transform-closed: skewX(-10deg) scaleY(0.8) rotate(16deg) translate(-50%, -50%);
}

.boxContainer {
  text-align: center;
  perspective: 500px;
  transform-style: preserve-3d;

  .boxImage {
    max-width: 90%;
    max-height: 90%;

    @include min-w(map-get($breakpoints, 'xxl') + 1) {
      max-width: 100%;
      max-height: 100%;
    }
  }

  &.isClosed {
    max-width: 380px;
    margin: -70px 0;
  }
}

.logoWrapper {
  position: absolute;

  &.isOpened {
    width: 40%;
    height: 25%;
    top: 33%;
    left: 42%;
    transform: var(--transform-opened);
  }

  &.isClosed {
    top: 43%;
    left: 44%;
    width: 41%;
    height: 33%;
    transform: var(--transform-closed);
  }
}

.sticker {
  width: 100%;
  height: 100%;
  position: absolute;
  transform-origin: center center;
  border-radius: 5%;
  background-color: var(--base-color);
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.logo {
  width: 80%;
  height: 80%;
  position: absolute;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  transform-origin: center center;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
