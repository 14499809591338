@import '../../variables';
@import '../../mixins';

.card {
  @include shadowed-box;

  margin: 10px;

  &.picked {
    border-color: $success-color;

    .checkmark {
      background-color: $success-color;
      border-color: $success-color;
    }
  }

  @include max-w(map-get($breakpoints, 'xs')) {
    margin: 0 0 10px;

    &:first-of-type {
      margin-top: 10px;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.itemContainer {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  padding: 10px;
  width: 100%;

  .itemImage {
    background-color: $white;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 70px;
    height: 70px;

    &:hover {
      cursor: zoom-in;
    }
  }

  .customizableItemIcon {
    position: absolute;
    top: 10px;
    right: 10px;
  }
}

.infoContainer {
  flex: 1;
  margin-left: 20px;

  @include max-w(map-get($breakpoints, 'xs')) {
    margin-left: 10px;
  }

  .headingContainer {
    .itemName {
      display: flex;
      align-self: flex-start;
      font-weight: 600;
      color: $ebony;
      font-size: 16px;
      margin-bottom: 7px;
    }
  }
}

.optionsContainer {
  font-size: 16px;
  color: $gray-suit;
  font-weight: 500;
  flex: 1;
  white-space: nowrap;
  text-transform: capitalize;
  position: relative;

  @include max-w(map-get($breakpoints, 'xs')) {
    font-size: 14px;
    font-weight: 400;
  }
}

.disabled {
  opacity: 0.35;
  pointer-events: none;
  user-select: none;
  cursor: not-allowed;
}

.outOfStockText {
  font-size: 16px;
  font-weight: 600;
  color: $orange;
  position: absolute;
  top: 1px;
  opacity: 1;
  z-index: 1;
}

.addMargin {
  margin-left: 8px;
}
