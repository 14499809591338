@import '../../../variables';
@import '../../../mixins';

.passwordForm {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.row {
  display: flex;
  justify-content: flex-start;
  padding: 0 12px;
}

.label {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: left;
  padding: 0;
  margin: 10px 0;
  color: #9297a3;
}

.inputWrapper {
  position: relative;
  max-width: 515px;
  padding: 0;

  svg {
    position: absolute;
    bottom: 20px;
    right: 20px;
  }
}

:global {
  .PhoneInputInput {
    padding: 0.375rem 0.75rem;
  }
  .PhoneInputCountrySelectArrow {
    margin-right: 2px;
  }
  .PhoneInputCountryIcon svg {
    bottom: 0;
    right: 0;
    position: relative;
  }
}

.input {
  input {
    height: 55px;
    padding-right: 46px;
    border-radius: 0.375rem;
    border: 1px solid #ced4da;

    &:focus {
      color: #212529;
      background-color: $white;
      border-color: #86b7fe;
      outline: 0;
      box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
    }
  }
}

.submit {
  height: 55px;
  max-width: 100%;

  @include min-w(map-get($breakpoints, 'md') + 1) {
    max-width: 320px;
  }
}
