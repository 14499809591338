@import 'src/variables';
@import 'src/mixins';

.container {
  @include cropped-text(1);

  display: flex;
  flex-flow: row;
}

.address {
  color: lighten($gray-suit, 1%);
}
