@import '../../variables';
@import '../../mixins';

.logo {
  height: auto;
  width: auto;
  max-width: 200px;
  max-height: 100px;

  @include max-w(map-get($breakpoints, 'xs')) {
    max-width: 150px;
  }
}
