@import '../../variables';

.arrowButton {
  border: 1px solid $ebony;
  border-radius: 50%;
  background: transparent;

  &:hover {
    border-color: var(--accent-color);

    path {
      fill: var(--accent-color);
    }
  }
}

.expanded {
  transform: rotate(180deg);

  vertical-align: unset;
}
