@import 'variables';

// Media Queries Min Width
@mixin min-w($width) {
  @media screen and (min-width: $width) {
    @content;
  }
}
// Media Queries Max Width
@mixin max-w($width) {
  @media screen and (max-width: $width) {
    @content;
  }
}
// Media Queries Range Width
@mixin min-max-w($min-width, $max-width) {
  @media screen and (min-width: $min-width) and (max-width: $max-width) {
    @content;
  }
}
// Media Queries Landscape orientation and max-width
@mixin max-landscape($width) {
  @media screen and (max-width: $width) and (orientation: landscape) {
    @content;
  }
}
// Media Queries Portrait orientation and max-width
@mixin max-portrait($width) {
  @media screen and (max-width: $width) and (orientation: portrait) {
    @content;
  }
}
@mixin max-h($height) {
  @media screen and (max-height: $height) {
    @content;
  }
}
@mixin max-landscape-h($height) {
  @media screen and (max-height: $height) and (orientation: landscape) {
    @content;
  }
}

// Thin scrollbars styling
$thumbHeight: 50px;

@mixin tiny-scrollbar($width: 8px, $track-color: rgba(112, 112, 112, 0.15)) {
  &::-webkit-scrollbar {
    width: $width;
  }

  &::-webkit-scrollbar-thumb {
    height: $thumbHeight;
    border-radius: 5px;
    background-color: lighten($track-color, 5%);
    transition: all 0.25s ease;

    &:hover {
      background-color: darken($track-color, 5%);
    }
  }

  &::-webkit-scrollbar-track {
    border-radius: 1.5px;
    background-color: transparent;
  }
}

@mixin cropped-text($lines: false) {
  overflow: hidden;
  text-overflow: ellipsis;

  @if ($lines) {
    display: -webkit-box;
    -webkit-line-clamp: $lines;
    -webkit-box-orient: vertical;
  }
}

@mixin shadowed-box {
  transition: box-shadow 0.25s ease;
  box-shadow: 0 0 0 transparent;

  &:hover {
    cursor: pointer;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  }
}

@mixin hovered-url($color: $default-accent-color) {
  color: $color;
  transition: all 0.25s ease;
  text-decoration: none;

  &:hover {
    color: darken($color, 15%);
    text-decoration: underline;
  }
}

@mixin hover-shadow($color: $ebony) {
  filter: drop-shadow(0 0 0 transparent);
  transition: all 0.25s ease;

  &:hover {
    filter: drop-shadow(2px 3px 3px rgba($color, 0.26));
  }
}

@mixin cropped-text($lines: false) {
  overflow: hidden;
  text-overflow: ellipsis;

  @if ($lines) {
    display: -webkit-box;
    -webkit-line-clamp: $lines;
    -webkit-box-orient: vertical;
  }
}
