@import 'variables';
$theme-colors: (
  'info': #fbab1c,
);

@import '~react-toastify/dist/ReactToastify.css';
@import '~bootstrap/scss/bootstrap';
@import '~react-image-lightbox/style.css';

body {
  margin: 0;
  font-family: $ff-gilroy;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: $bg-color;
  min-height: 100vh;
  color: $default-base-color;

  * {
    box-sizing: border-box;
  }
}

#root {
  display: flex;
  flex-flow: column;
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
