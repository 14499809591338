@import '../../variables';
@import '../../mixins';

.input {
  position: absolute;
  visibility: hidden;
}

.radioButton {
  font-size: 21px;

  position: relative;
  display: flex;
  flex: auto;
  flex-flow: row;
  width: auto;
  padding: 0;
  color: #212529;
  border: none;
  cursor: pointer;

  border-radius: 10px;
}
