@import '../../variables';
@import '../../mixins';

.list {
  @include tiny-scrollbar;

  max-height: 360px;
  display: flex;
  flex-flow: column;
  align-items: stretch;
  justify-content: flex-start;
  overflow-y: auto;

  .item {
    position: relative;
    padding: 8px 10px 5px;
    min-height: 60px;
    background: $white;
    display: flex;
    flex-flow: column;
    text-align: left;
    align-items: stretch;
    justify-content: center;
    transition: all 0.25s ease;
    border: none;

    &.noResults {
      text-align: center;
    }

    &:not(:first-of-type) {
      border-top: 1px solid $gray-blue;
    }

    &:not(.noResults):hover {
      box-shadow: inset 0 -2px 10px rgba(0, 0, 0, 0.08);
    }

    .caption {
      font-size: 14px;
      margin-bottom: 5px;
      display: flex;

      &.selected {
        font-weight: 600;
      }
    }
  }
}
