@import './src/mixins';
@import './src/variables';

.footerContainer {
  padding: 18px 0;
  color: $white;

  font-size: 14px;

  &.lightBackground {
    color: $ebony;
  }

  @include max-w(map-get($breakpoints, 'xs')) {
    padding: 10px 0;
    text-align: center;
    font-size: 10px;
  }
}
