@import '../../variables';
@import '../../mixins';

.inputContainer {
  position: relative;

  .errorBorder {
    border: 1px solid $error-color;

    &:focus {
      box-shadow: 0 0 0 0.2rem #de4242ad;
    }
  }
}

.clearButton {
  position: absolute;
  padding: 0;
  margin: 0;
  outline: none;
  display: flex;
  box-shadow: none;
  border: none;
  opacity: 0.85;
  right: 0;
  top: 50%;
  transform: translate(-100%, -50%);
  width: 20px;
  height: 20px;
  transform-origin: center center;
  transition: 0.25s ease;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;

  &:hover {
    opacity: 1;
  }

  &:active {
    outline: none;
  }
}

.asterisk {
  color: $error-color;
}

.error {
  font-size: 12px;
  position: absolute;
  bottom: -1.7em;
  line-height: 1.15;
  color: $error-color;
}

.suggestionsList {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  background: white;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.16);
  z-index: 100;
}

.suggestionsListItem {
  position: relative;
  width: 100%;
  background: white;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 0.4em 1em;
  border-radius: 4px;
  transition: all 0.25s ease;
  text-align: left;

  &:not(:last-of-type) {
    &:after {
      content: '';
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 90%;
      height: 1px;
      background: #e5e5e5;
    }
  }

  &:hover {
    background: #f7f7f7;
  }
}
