.loader {
  position: absolute;
  top: 50%;
  left: 40%;
  margin-left: 10%;
  transform: translate3d(-50%, -50%, 0);
}

.loaderContainer {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fff;
  z-index: 100;
  width: 100%;
  height: 100%;
}

.fullScreen {
  position: fixed;
}

.dot {
  width: 24px;
  height: 24px;
  background: #3ac;
  border-radius: 100%;
  display: inline-block;
  animation: slide 1s infinite;
}
@for $n from 1 through 5 {
  .dot:nth-child(#{$n}) {
    animation-delay: (0.1s * $n);
    background: rgba(#3ac, (50 * $n));
  }
}

@keyframes slide {
  0% {
    transform: scale(1);
  }
  50% {
    opacity: 0.3;
    transform: scale(2);
  }
  100% {
    transform: scale(1);
  }
}
