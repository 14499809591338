$ff-gilroy: 'Gilroy', Arial, sans-serif;

$breakpoints: (
  xxs: 320px,
  xs: 576px,
  md: 768px,
  lg: 960px,
  xl: 1200px,
  xxl: 1368px,
  xxxl: 1800px,
);

:root {
  --base-color: #212121;
  --accent-color: #5f11ea;
  --breakpoint-xs: #{map-get($breakpoints, xs)};
  --breakpoint-md: #{map-get($breakpoints, md)};
  --breakpoint-lg: #{map-get($breakpoints, lg)};
  --breakpoint-xl: #{map-get($breakpoints, xl)};
  --breakpoint-xxl: #{map-get($breakpoints, xxl)};
  --breakpoint-xxxl: #{map-get($breakpoints, xxxl)};
}

$success-color: #38ca60;
$white: #ffffff;
$ebony: #2b2f38;
$gray-blue: #bbc2d3;
$gray-suit: #9197a5;
$gray-disabled: #e2e5eb;
$gray-light: #fafafc;
$gray-background: #e5e5e5;
$error-color: #de4242;
$error-border-color: #de4242ad;
$bg-color: #f6f6f6;
$url-blue: #00aeff;
$orange: #efac45;
$gray-input: #ced4da;

// defaults
$default-base-color: #212121;
$default-accent-color: #5f11ea;
$default-result-screen-color: #ffffff;

// Sidebars
$mobile-nav-height: 70px;
$nav-desktop-min-width: 80px;
$nav-desktop-min-widthXXL: 81px;
$nav-desktop-max-width: 300px;
$nav-mobile-min-width: 0px;
$nav-mobile-max-width: 259px;

$org-sidebar-min-width: 0px;
$org-sidebar-max-widthHD: 679px;
$org-sidebar-max-widthFullHD: 938px;
$org-sidebar-min-height: 0vh;
$org-sidebar-max-height: 100vh;

$box-sidebar-min-width: 0px;
$box-sidebar-min-height: 0vh;
$box-sidebar-max-height: 100vh;
$box-sidebar-max-widthHD: 649px;
$box-sidebar-max-widthFullHD: 737px;

:export {
  defaultBaseColor: $default-base-color;
  defaultAccentColor: $default-accent-color;
  defaultResultScreenColor: $default-result-screen-color;
}
