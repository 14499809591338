@import '../../variables';
@import '../../mixins';

.title {
  margin-bottom: 0.8em;
  font-family: $ff-gilroy;
  font-weight: 700;

  @include max-w(map-get($breakpoints, 'xs')) {
    font-size: 30px;
  }
}

.sender {
  margin-bottom: 2rem;
  font-style: oblique;
}

.customBodyContainer {
  padding-bottom: 30px;
}
