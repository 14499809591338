@import 'src/variables';
@import 'src/mixins';

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  border-radius: 10px;
  padding: 20px 15px;
  background: $white;

  .slider {
    height: 100%;
  }

  .title,
  .info {
    color: $default-base-color;
  }

  .title {
    font-size: 18px;
    font-weight: 600;
  }

  .info {
    font-size: 16px;
    font-weight: 400;
  }

  .pickButton {
    @include hover-shadow();

    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
    width: 100%;
    background-color: var(--accent-color);
    border-radius: 3px;
    height: 50px;
    font-size: 18px;
    color: $white;
    font-weight: 600;

    &.light {
      color: $ebony;
    }

    @include max-w(map-get($breakpoints, 'xs')) {
      font-size: 16px;
    }
  }

  &.dimmed {
    opacity: 0.5;
  }

  &.picked {
    opacity: 1;

    .pickButton {
      background-color: $success-color;
      color: $ebony;
    }
  }

  &:hover {
    opacity: 1;
  }
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  .headingContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;

    p {
      margin: 0;
    }
  }
}

.itemList {
  @include tiny-scrollbar();

  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  overflow: hidden;
  align-items: flex-start;
  margin-top: 10px;
  flex: 1;

  &.multipleMode {
    overflow: auto;
    padding-right: 13px;
    padding-left: 5px;
  }
}

.digitalLabel {
  font-size: 11px;
  font-weight: 700;
  line-height: 13px;
  width: 100%;
  text-align: center;
  margin: 5px 0;

  &:has(+ .pickButton) {
    margin-bottom: 20px;
  }
}

.preorderLabel {
  font-size: 16px;
  line-height: 1.2;
  font-style: italic;
  margin-top: 20px;
  color: $default-accent-color;
}

.disabled {
  background-color: $gray-blue !important;
  cursor: not-allowed;
}
