.swiper-button-next {
  background-image: url('/src/assets/icon-next.svg');
  background-repeat: no-repeat;
  background-size: 50% auto;
  background-position: right;
  top: 40%;
}

.swiper-button-prev {
  background-image: url('/src/assets/icon-prev.svg');
  background-repeat: no-repeat;
  background-size: 50% auto;
  background-position: left;
  top: 40%;
}

.swiper-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-pagination-bullet {
  width: 10px !important;
  height: 10px;
}

.swiper-button-next::after, .swiper-button-prev::after {
  display: none;
}

.swiper-slide {
  padding: 4px;
}
