@import 'src/variables';
@import 'src/mixins';

.headerWrapper {
  position: relative;
  padding: 10px 0;

  &.customBackground {
    position: relative;
    background-image: url('../../assets/mystery-background.svg');
    background-size: cover;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 2;

    @include max-w(map-get($breakpoints, 'xs')) {
      background-image: none;
    }
  }
}

.wave {
  position: absolute;
  right: 0;
  overflow: hidden;
  background-image: url('../../assets/icon-wave.svg');
  background-position: calc(50% + 20vw) bottom;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.customBackground .wave {
  display: none;
}

.content {
  position: relative;
  z-index: 2;
}

.headingContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  gap: 30px;
}

.greeting {
  display: flex;
  flex-flow: column;
  justify-content: center;
  flex: 1 0;
  width: 100%;

  &.lightBackground {
    color: $ebony;
  }
}

.logoContainer {
  z-index: 0;
  padding: 30px 0;

  @include max-w(map-get($breakpoints, 'xs')) {
    padding: 10px 0;
  }
}

.sendBox {
  position: relative;
  margin: -50px 0;
  flex: 1 1;
  max-width: 284px;

  @include min-w(map-get($breakpoints, 'xxl') + 1) {
    max-width: 350px;
  }

  @include min-w(map-get($breakpoints, 'xl') + 1) {
    max-width: 284px;
  }
}

.headingContainer {
  color: $white;
  padding: 0 0 20px;

  @include max-w(map-get($breakpoints, 'xs')) {
    padding-top: 20px;
  }
}

.breadcrumbsContainer {
  justify-content: flex-start;
  margin: auto 0 20px;

  @include max-w(map-get($breakpoints, 'xs')) {
    margin-top: 10px;
  }

  .stepperContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    a {
      text-decoration: none;
    }
  }
}

.stepperLabel {
  color: $white;

  &.dark {
    color: $ebony;
  }
}

.mysteryBoxHeader {
  @include max-w(map-get($breakpoints, 'xs')) {
    position: relative;
    background-image: url('../../assets/mystery-background.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: -180px;
    width: 100%;
    top: 0;
    left: 0;

    .sticky {
      position: static;
    }

    .sticky:first-child {
      padding: 0;
    }
  }
}

.sticky {
  position: sticky;
  top: 0;
  z-index: 99;
}
