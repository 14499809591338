@import '../../variables';
@import '../../mixins';

.shippingForm {
  background: white;
  margin: 25px 0;
}

.row {
  margin-bottom: 1.3rem;

  @include max-w(map-get($breakpoints, 'xs')) {
    display: flex;
    flex-direction: column;
  }
}

.tooltip {
  background: $gray-suit;
}

.title {
  text-transform: uppercase;
  font-weight: 600;

  @include max-w(map-get($breakpoints, 'xs')) {
    font-size: 18px;
  }
}

.subtitle {
  font-weight: 500;
  font-size: 16px;
}
