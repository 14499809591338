@import '../../variables';
@import '../../mixins';

.title {
  font-size: 36px;
  margin-bottom: 1em;

  @include max-w(map-get($breakpoints, 'xs')) {
    font-size: 30px;
  }
}

.mskuContainer {
  flex: 1;
  margin-bottom: 50px;

  @include max-w(map-get($breakpoints, 'xs')) {
    margin-bottom: 0;
  }
}

.optionList {
  @include tiny-scrollbar();
}

.accordionContainer {
  width: 100%;
}

.option {
  margin-bottom: 30px;

  .itemContainer {
    border-radius: 10px;
    background-color: white;

    span {
      width: 100%;
    }
  }
}

.continueRow {
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
}

.pickedCampaignContainer {
  .pickedCampaign {
    height: 500px;
    max-width: 362px;

    @include max-w(map_get($breakpoints, lg)) {
      margin: 0 auto 0;
    }

    @include max-w(map_get($breakpoints, xs)) {
      height: auto;
      margin-bottom: 10px;
    }
  }
}
