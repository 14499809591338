@import '../../mixins';
@import '../../variables';

.container {
  display: flex;
  flex-wrap: nowrap;
  gap: 20px;
  list-style-type: none;
  margin: 0;
  padding: 0;
  width: 100%;
  justify-content: center;
  height: 100%;

  @include max-w(map-get($breakpoints, 'xs')) {
    gap: 10px;
  }
}

.stepItem {
  display: flex;
  align-items: flex-start;

  &:before {
    align-self: center;
    transition: all 0.25s ease;
    content: '';
    position: relative;
    top: 50%;
    width: 4vw;
    height: 2px;
    background: var(--accent-color);
    opacity: 0.4;

    @include min-w(map-get($breakpoints, 'md') + 1) {
      width: 2vw;
      max-width: 40px;
    }

    @include max-w(map-get($breakpoints, 'xs')) {
      margin-right: 10px;
    }
  }

  &.active {
    &:before {
      background: var(--accent-color);
    }
  }

  &:first-of-type:before {
    content: none;
  }
}

.stepItemMystery {
  .stepItemMystery {
    background: #ffffff;
  }

  &.active {
    &:before {
      background: #ffffff;
    }
  }
}

.label {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  white-space: nowrap;

  .labelText {
    margin-left: 20px;
    line-height: 1;

    @include max-w(map-get($breakpoints, 'xs')) {
      max-width: 5rem;
      white-space: initial;
      margin-left: 10px;

      &.labelMysteryBox {
        max-width: 12em;
      }
    }
  }

  .stepCount {
    height: 40px;

    font-size: 21px;
    font-weight: 700;

    fill: var(--accent-color);
    opacity: 0.5;

    & .stepCircleMystery {
      fill: #ffffff;
    }

    &.active {
      opacity: 1;

      .stepCircle {
        fill: var(--accent-color);
      }
    }

    &.current {
      cursor: default;
    }

    @include max-w(map-get($breakpoints, 'xs')) {
      height: 30px;
    }
  }

  @include max-w(map-get($breakpoints, 'xs')) {
    font-size: 14px;
  }
}
