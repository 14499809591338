@import 'src/variables';
@import 'src/mixins';

.item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 48%;
  padding-bottom: 2em;
  margin-bottom: 0;

  &.bigSize {
    width: 100%;
    height: 100%;

    .image {
      width: 322px;
      height: 322px;

      @include max-w(map-get($breakpoints, 'xs')) {
        width: 268px;
        height: 268px;
      }
    }

    .name {
      margin-bottom: 2em;
    }
  }
}

.metadataContainer {
  position: absolute;
  right: 4px;
  top: 0;
  display: flex;
  flex-flow: column;
  align-items: flex-end;

  > *:not(:first-child) {
    margin-top: 5px;
  }
}

.quantity {
  width: 100%;
  max-width: 28px;
  height: 28px;
  border-radius: 3px;
  background-color: $gray-blue;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: $ebony;
  padding: 3px 4px 0;
}

.customizableItemIcon {
  flex-shrink: 0;
}

.image {
  height: 150px;
  width: 150px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 95%;

  img {
    max-height: 100%;
    max-width: 100%;
    height: auto;
    width: auto;
  }
  &.notScalable {
    background: rgba(223, 223, 223, 0.2);
    border-radius: 20px;
  }
}

.name {
  @include tiny-scrollbar($width: 4px);

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  cursor: default;
  font-size: 16px;
  text-align: center;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  transition: all 0.25s ease;
  background: white;
  filter: drop-shadow(0px 0px 1px transparent);
  padding: 0.2em;

  &:hover {
    display: -webkit-box;
    -webkit-line-clamp: unset;
    overflow: auto;
    white-space: initial;
    max-height: 100%;
    filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.1));
  }
}
