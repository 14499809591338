@import '../../variables';
@import '../../mixins';

.container {
  flex: 1;
  display: flex;
  flex-flow: column;
  align-items: stretch;
  justify-content: center;
  text-align: center;
  padding: 0 1rem;

  @include min-w(map-get($breakpoints, 'xs')) {
    padding: 0;
  }
}

.header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 0;

  @include min-w(map-get($breakpoints, 'xs')) {
    padding: 30px 0 0;
  }
}

.main {
  display: flex;
  color: $white;
  flex: 1;
  align-items: center;
}

.content {
  --bs-gutter-x: 0;
  width: 100%;
  flex-flow: column;
  align-items: stretch;
  gap: 25px;

  @include min-w(map-get($breakpoints, 'md')) {
    align-items: center;
    flex-flow: row;
  }
}

.title {
  font-family: $ff-gilroy;
  font-weight: 700;
  font-size: 30px;
  line-height: 1.3;
  margin-bottom: 10px;
  text-align: center;

  @include min-w(map-get($breakpoints, 'lg')) {
    font-size: 48px;
  }

  @include min-w(map-get($breakpoints, 'md')) {
    text-align: left;
  }

  @include min-w(map-get($breakpoints, 'xs')) {
    font-size: 36px;
  }
}

.message {
  font-size: 16px;
  word-break: break-word;
  text-align: center;

  @include min-w(map-get($breakpoints, 'md')) {
    text-align: left;
  }

  a {
    @include hovered-url();

    color: var(--accent-color);
  }
}

.focusImage {
  max-width: 100%;
  max-height: 46vh;
}

.footer {
  font-size: 14px;
  line-height: 1.2;
  padding-bottom: 14px;
  color: $white;
  display: flex;
  flex-flow: column;
  align-items: center;
}

.powered {
  margin-top: 5px;
}

.footerLink {
  @include hovered-url();

  color: var(--accent-color);
}

.lightBackground {
  color: $ebony;
}
