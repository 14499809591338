@import '../../variables';
@import '../../mixins';

.contentContainer {
  flex: 1;
  margin-bottom: 50px;

  @include max-w(map-get($breakpoints, 'xs')) {
    margin-bottom: 30px;
  }
}

.itemsList {
  @include tiny-scrollbar();

  display: flex;
  flex-direction: column;
  gap: 10px;
}

.continueRow {
  display: flex;
  justify-content: center;
  margin-bottom: 50px;

  @include max-w(map-get($breakpoints, 'xs')) {
    margin-bottom: 30px;
  }
}
