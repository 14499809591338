@import '../../variables';
@import '../../mixins';

.button {
  @include hover-shadow();

  background-color: var(--accent-color);
  border-color: var(--accent-color);
  outline: none;
  box-shadow: none;
  max-width: 322px;
  width: 100%;
  font-size: 18px;
  border-radius: 5px;
  font-weight: 400;
  padding: 0.6em;
  color: $ebony;

  @include max-w(map-get($breakpoints, 'xs')) {
    font-size: 16px;
    max-width: 238px;
    padding: 0.45em;
  }

  &:hover {
    color: $ebony;
    background-color: var(--accent-color);
    border-color: var(--accent-color);
    transform: scale(1.01);
    font-weight: 600;
  }
}

.dark {
  color: $white;

  &:hover {
    color: $white;
  }
}

.light {
  border: 0.5px solid $gray-blue;
}
