@import '../../../variables';

.icon {
  width: 28px;
  height: 28px;
  background-color: $white;
  border-radius: 3px;
  background-position: center center;
  background-size: 20px 20px;
  background-repeat: no-repeat;
}
