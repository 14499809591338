@import 'src/variables';

.container {
  display: flex;
  border: 0.5px solid $gray-disabled;
  border-radius: 5px;
  max-width: 60px;
  max-height: 30px;
  align-items: center;
  overflow: hidden;

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0 5px 5px 0;
    width: 30px;

    svg {
      height: 30px;
      margin-top: 1px;

      circle {
        fill: $gray-disabled;
      }
    }

    &.single {
      border-radius: 5px 0 0 5px;
    }

    &.active {
      cursor: initial;
      background-color: $gray-blue;
      border-radius: 0;

      svg {
        circle {
          fill: $gray-light;
        }
      }
    }

    &:hover {
      cursor: pointer;
    }
  }
}
