.title {
  text-transform: uppercase;
  font-weight: 600;
  margin-top: 35px;
}

.content {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: auto;
  background: #fff;
  border-radius: 10px;
  padding: 10px 0;
  font-size: 18px;
}

.label {
  font-weight: 600;
}
