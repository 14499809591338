@import '../../variables';
@import '../../mixins';

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  .errorBorder {
    border: 1px solid $error-color;

    &:focus {
      box-shadow: 0 0 0 0.2rem $error-border-color;
    }
  }
}

.asterisk {
  color: $error-color;
}

.error {
  font-size: 12px;
  position: absolute;
  bottom: -1.7em;
  line-height: 1.15;
  color: $error-color;
  width: max-content;
}

.disabled {
  cursor: not-allowed;
}
