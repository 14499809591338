@import '../../variables';
@import '../../mixins';

.title {
  font-size: 36px;
  margin-bottom: 1em;

  @include max-w(map-get($breakpoints, 'xs')) {
    font-size: 30px;
  }
}

.body {
  margin-bottom: 1rem;
}

.sender {
  margin-bottom: 2rem;
  font-style: oblique;
  font-weight: 300;
}

.pygContainer {
  flex: 1;
  margin-bottom: 50px;

  @include max-w(map-get($breakpoints, 'xs')) {
    margin-bottom: 0;
  }
}

.optionList {
  @include tiny-scrollbar();
  padding-top: 30px;

  &.centeredList {
    justify-content: center;
  }
}

.option {
  margin-bottom: 30px;

  .radioContainer {
    background-color: white;
    height: 570px;

    span {
      width: 100%;
    }
  }
}

.continueRow {
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
}
