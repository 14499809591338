@import '../../variables';
@import '../../mixins';

.AppHeader {
  padding: 2rem 0;
  display: flex;
  height: 90px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);

  @include max-w(map-get($breakpoints, 'xs')) {
    padding: 0.75rem 0;
    position: sticky;
    top: 0;
    z-index: 10;
  }
}

.AppWrapper {
  margin-top: 1.5rem;
  flex: 1;
  display: flex;
  flex-flow: column;
  background-color: $bg-color;
}

.contentRow {
  flex: 1;
}

.contentWrapper {
  flex: 1;
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
}

.AppFooter {
  background: #30364d;
  color: white;
  padding: 2rem 0;

  @include max-w(map-get($breakpoints, 'xs')) {
    padding: 0.75rem 0;
    position: sticky;
    bottom: 0;
    z-index: 100;
    font-size: 14px;
    text-align: center;
  }
}

.breadcrumbs {
  justify-content: flex-start;
  margin: 60px 0 10px;

  @include max-w(map-get($breakpoints, 'xs')) {
    margin-top: 30px;
  }

  .stepperContainer {
    justify-content: flex-start;

    a {
      text-decoration: none;
    }
  }
}

.logoContainer {
  .brandLogo {
    height: auto;
    width: auto;
    max-width: 100%;
    max-height: 100px;
  }
}

.safetyMessage {
  position: static;
  text-align: center;
  margin: 1.4rem 0 2rem;
  font-size: 14px;

  @include max-w(map-get($breakpoints, 'xs')) {
    font-size: 10px;
  }
}
