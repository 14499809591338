@import 'src/variables';
@import 'src/mixins';

.container {
  position: relative;
  width: 100%;
  border: 1px solid $gray-input;
  font-size: 16px;
  text-align: start;
  padding: 8px 15px;
  display: flex;
  align-items: center;

  &:after {
    margin-left: 10px;
  }

  &:hover {
    border: 1px solid $gray-input;
  }

  &.error {
    border-color: $error-color;

    &:focus {
      box-shadow: 0 0 0 0.2rem $error-border-color;
    }
  }
}

.errorText {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  color: $error-color;
  font-size: 12px;
}

.toggle {
  @include cropped-text(1);

  width: 100%;
  display: flex;

  &.trailingComa {
    > :first-child:not(:only-child) {
      &:after {
        content: ',';
        margin-right: 5px;
      }
    }
  }
}

.menu {
  inset: 0 0 auto !important; // needed to overwrite the default bootstrap's value
  transform: translate3d(0px, 78px, 0px) !important; // needed to overwrite the default bootstrap's value

  &:global(.show) {
    display: flex;
    overflow: hidden;
  }
  flex-direction: column;
  max-height: 250px;
}

.options {
  @include tiny-scrollbar(6px);

  margin-top: 10px;
  overflow-y: auto;

  .item {
    display: flex;
    flex-flow: column;
    transition: all 0.25s ease;

    &.selected {
      pointer-events: none;
      cursor: default;

      background-color: $gray-light;
    }
  }
}

.label {
  color: $gray-suit;
  margin-bottom: 10px;
}

.search {
  width: unset; // needed to overwrite the default bootstrap's value
  margin: 0 10px;
}
