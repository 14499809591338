@import '../../variables';
@import '../../mixins';
@import '../../../node_modules/bootstrap/scss/functions';
@import '../../../node_modules/bootstrap/scss/variables';
@import '../../../node_modules/bootstrap/scss/mixins/breakpoints';

.container {
  margin-top: 30px;
  flex: 1;
}

.contentRow {
  display: flex;
  justify-content: center;
}

.formContainer {
  position: relative;

  :global {
    .col,
    .col-5,
    .col-6,
    .col-7,
    .col-12 {
      .form-label {
        text-transform: capitalize;
        font-family: $ff-gilroy;
        font-weight: 400;
        font-size: 14px;
        margin-bottom: 5px;
        color: $gray-suit;
      }

      @include max-w(map-get($breakpoints, 'xs')) {
        &:not(:last-of-type) {
          margin-bottom: 1.3rem;
        }
      }
    }
  }
}

.form {
  width: 100%;

  &.contact {
    margin-top: 0;
  }
}

.requiredMessage {
  padding-left: 0;
  padding-right: 0;
}

.actions {
  padding-left: 0;
  padding-right: 0;
  padding-top: 20px;
  display: flex;
  justify-content: center;
}

.asterisk {
  color: $error-color;
}

:global {
  .btn-info {
    width: 100%;
    color: white;
    text-transform: uppercase;
    font-weight: bold;
  }
}

.pickedCampaignContainer {
  .pickedCampaign {
    height: 500px;
    max-width: 362px;
    margin: 0 auto;

    @include min-w(map_get($breakpoints, xl)) {
      margin-top: 3.4em;
    }

    @include max-w(map_get($breakpoints, xs)) {
      height: auto;
      margin-bottom: 10px;
    }
  }
}

.formWrapper {
  padding: 20px 30px;
  background: $white;
  border-radius: 10px;
  margin-top: 30px;

  @include max-w(map_get($breakpoints, xs)) {
    padding: 15px;
    margin-top: 15px;
  }
}

.toast {
  & a {
    @include hovered-url();
    cursor: pointer;
    color: $white;
    text-decoration: underline;
  }
}
