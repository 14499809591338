.container {
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.image {
  max-width: 100%;

  &:hover {
    cursor: zoom-in;
  }

  &.notScalable {
    &:hover {
      cursor: default;
    }
  }
}
