@import '../../mixins';

.container {
  @include tiny-scrollbar;

  position: relative;
  box-sizing: border-box;
  max-width: 100%;
  width: 100%;
  overflow: auto;

  strong,
  em,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  sup,
  sub,
  code,
  blockquote,
  pre,
  p,
  b,
  ol,
  ul {
    all: revert;
  }
}
