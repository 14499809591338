@import '../../variables';
@import '../../mixins';

.container {
  border: 1px solid $gray-disabled;
  border-radius: 10px;

  @include shadowed-box;
}

.itemContentContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;

  .input {
    max-width: 50%;

    label {
      margin: 0;
      font-size: 12px;
      max-width: 70%;
      color: $gray-suit;
      font-weight: 400;
    }

    @include max-w(map-get($breakpoints, 'xs')) {
      max-width: unset;

      label {
        max-width: unset;
      }
    }
  }

  .info {
    display: flex;
    align-items: center;

    .name {
      font-size: 18px;
      font-weight: bold;
      margin: 0 0 0 15px;

      @include max-w(map-get($breakpoints, 'xs')) {
        font-size: 16px;
      }
    }

    .itemImage {
      background-color: $white;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center center;
      width: 100px;
      height: 100px;
      margin: 0;

      &:hover {
        cursor: zoom-in;
      }

      @include max-w(map-get($breakpoints, 'xs')) {
        width: 80px;
        height: 80px;
        margin: 0;
      }
    }
  }

  @include max-w(map-get($breakpoints, 'xs')) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
  }
}

.requiredLabel {
  color: $error-color;
  font-size: 14px;
  font-weight: 400;
  position: absolute;
  top: 4px;
  right: 10px;
  width: unset !important;

  @include max-w(map-get($breakpoints, 'xs')) {
    font-size: 12px;
  }
}
