@import 'src/variables';
@import 'src/mixins';

.slideWrapper {
  .bullet {
    width: 10px;
    height: 10px;

    &.active {
      background: var(--accent-color);
    }
  }

  .nextButton {
    color: $ebony;
  }
}
