@import '../../variables';

.tabs {
  display: flex;
  width: 100%;
  gap: 20px;
  margin: 25px 0;

  &.error {
    position: relative;

    .tab {
      border-color: $error-color;
    }
  }
}

.tab {
  border-radius: 8px;
  border: 1px solid $gray-suit;
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 20px;
  flex: 1;
  justify-content: center;
  transition: all 0.25s ease;
  font-size: 16px;
  font-weight: 500;
  box-shadow: 0 0 1px transparent;

  &:hover {
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.15);

    .icon {
      transform: scale(1.1);
    }
  }
}

.icon {
  max-width: 15px;
  transition: all 0.25s ease;
  transform-origin: center center;

  path {
    transition: all 0.25s ease;
    fill: $gray-suit;
  }
}

.active {
  border-color: var(--accent-color);
  color: var(--accent-color);

  .icon {
    path {
      fill: var(--accent-color);
    }
  }
}

.errorMessage {
  position: absolute;
  top: 100%;
  left: 50%;
  margin-top: 10px;
  transform: translateX(-50%);
  color: $error-color;
  font-size: 12px;
}
